export const BRANDING = {
    perfilId: 3580,//4786, // 4301 = dev,  4786 = prod
    recaptcha: { // RECAPTCHA
        secret_key: "6Le_ASQTAAAAADEgmC1b5YUyFIhwztR_FJ7OMgv0"
    },
    apiHost: "https://api.clinicas2.simdoctor.com.br/v1/clinicas", // HOST API
    internal: { // LOGIN INTERNO
        email: "internoSimdoctor",
        password: "?AH%7Vd!nRBdjQRx",
        perfilId: 3580,
    },
    Colors: { // CORES
        primary: '#104267',
        secondary: '#104267',
        tertiary: '#00d5ff',
        quaternary: '#86fce2',
        quinary: '#1d68b9',
        senary: '',
        white: '#FFFFFF',
        black: '#333333',
        grey: '#e7e7e7',
        red: '#f44336',
        alert: '#fbf5c4',
        warning: '#ffa500',
        border: '#e7e7e7'
    },
    titleHeader: "IBAC", // NOME DO SITE
    subtitleHeader: "Terapia comportamental online", // Slogan do site
    filterLogo: { // IMAGEM DO FILTRO
        img: require("./assets/images/ibac/filter_logo.png") ?? "", 
        width: 280
    }, // IMAGEM NA TELA DE LOGIN
    loginLogo: {
        img: require("./assets/images/ibac/logo_login.png") ?? "",
        height: 125
    }, // TEXTO NA HOME PAGE
    homeText: "O IBAC é o maior centro de psicologia comportamental da região centro-oeste, tanto como centro formador de psicólogos através de seus cursos de pós-graduação, como também enquanto prestador de serviços na área clínica."
}